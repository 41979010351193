import React, { useEffect, useState } from "react";
import { useStoreState } from "easy-peasy";

const HomeBanner = ({ homeBanner }) => {
  const getUserValue = useStoreState((state) => state.user.userInfo);

  const createSamlRequest = () => {
    window.location.href =
    global.catalogUrl+"/b2r/SAML/login";
  };

  const date = new Date();
  const startDate = new Date("12/19/2024, 12:01:00 AM").getTime();
  const endDate = new Date("1/14/2025, 11:59:00 PM").getTime();
  const currentDate = new Date(date.toUTCString()).getTime();

  const [promitionVisible, setPromotionVisible] = useState(false);
  const [bannerImage, setBannerImage] = useState('');

  function displayPromotion(){
    if(currentDate > startDate && currentDate < endDate){
      setPromotionVisible(true)
    }
  };


  const changeSeasonalBanner = () => {
    //this logic applies for the image banner depending of the date
    const currentYear = date.getFullYear().toString();
    const banners = [
      { id: "pnc-snowflakes-banner", startDate: new Date(`01/01/${currentYear}, 12:00:00 AM`), endDate: new Date(`02/28/${currentYear}, 11:59:59 PM`) },
      { id: "pnc-spring-flowers-banner", startDate: new Date(`03/01/${currentYear}, 12:00:00 AM`), endDate: new Date(`05/31/${currentYear}, 11:59:59 PM`) },
      { id: "pnc-sunset-banner", startDate: new Date(`06/01/${currentYear}, 12:00:00 AM`), endDate: new Date(`08/31/${currentYear}, 11:59:59 PM`) },
      { id: "pnc-camping-banner", startDate: new Date(`09/01/${currentYear}, 12:00:00 AM`), endDate: new Date(`10/31/${currentYear}, 11:59:59 PM`) },
      { id: "pnc-christmas-presents-banner", startDate: new Date(`11/01/${currentYear}, 12:00:00 AM`), endDate: new Date(`12/31/${currentYear}, 11:59:59 PM`) }
    ];
  
    const activeBanner = banners.find((banner) => {
      const startDate = banner.startDate.getTime();
      const endDate = banner.endDate.getTime();
      return currentDate >= startDate && currentDate <= endDate;
    });
    if (activeBanner) {
      setBannerImage(activeBanner.id);
    }
  }

  useEffect(() => {
    displayPromotion();
    changeSeasonalBanner();
  },[]);

  return (
    <div>
      <section
        className={getUserValue ? `pnc-hero-banner log-in ${bannerImage}` : `pnc-hero-banner ${bannerImage}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {homeBanner ? (
                <>
                { promitionVisible && getUserValue ? <a href={global.catalogUrl+"/b2r/SAML/login?supplier=GiftCard&shop=giftcardfeatureholiday2024"}><div className="pnc-gift-promotion"></div></a>  : null }
                <div className={promitionVisible && getUserValue ? "pnc-banner-content promotion-top-distance" : "pnc-banner-content"}>
                  <h1
                    dangerouslySetInnerHTML={{ __html: homeBanner.title }}
                  ></h1>
                  <p dangerouslySetInnerHTML={{ __html: homeBanner.desc }}></p>
                  {getUserValue &&
                    <button className="btn" onClick={createSamlRequest}>
                      {homeBanner.buttonText}
                    </button>
                  // ) : (
                  //   <a href={global.catalogUrl+"/b2r/landingHome.do?anon=1"}>
                  //     <button className="btn">Shop Now</button>
                  //   </a>
                  }
                </div>
                </>
              ) : (
                false
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeBanner;
